@font-face {
  font-family: 'Digital-7';
  src: url('fonts/Digital-7.eot');
  src: url('fonts/Digital-7.eot?#iefix') format('embedded-opentype'),
      url('fonts/Digital-7.woff2') format('woff2'),
      url('fonts/Digital-7.woff') format('woff'),
      url('fonts/Digital-7.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}